<template>
  <div class="container pd100">
    <div v-if="address" class="address-box mb10" @click="onAddressChoose">
      <div class="address-box-hd">
        <div class="address-box-inner">
          <van-icon name="location-o" class="address-box-inner-icon" />
          <div class="address-box-inner-title">收货人：{{ address.name }}</div>
          <div class="address-box-inner-title">{{ address.tel }}</div>
        </div>
        <div class="address-box-inner-bottom">收货地址：{{ address.address }}</div>
      </div>
      <div class="address-box-bd"><van-icon name="arrow" /></div>
    </div>
    <van-cell v-else class="address-card mb10" title="新增收货地址" icon="add-square" is-link @click="onAddressChoose" />
    <div class="oreder-info">
      <van-cell-group>
        <van-cell title="积分商品列表" />
        <van-card
          v-for="(item,index) in cartList"
          :key="index"
          currency="积分"
          :num="item.count"
          :desc="item.specname"
          :title="item.product_name"
          :thumb="item.catimg"
        >
          <div slot="price" class="aaa">
            {{ item.sale_price }} 积分
          </div>
        </van-card>
      </van-cell-group>
    </div>
    <!-- <div class="affix-bar">
      <van-button type="primary" block @click="onSubmit">兑换</van-button>
    </div> -->
    <van-submit-bar
      button-text="确认兑换"
      @submit="onSubmit"
    >
      <div slot="default" class="submit-bar-left">
        <span>合计：</span>
        <span class="submit-bar-price"> {{ totalPrice }}积分</span>
      </div>
    </van-submit-bar>
  </div>
</template>
<script>
import { Card, Field, CouponCell, CouponList, SubmitBar, Switch } from 'vant'
import PageMixin from '@/mixins/page'
import storage from 'good-storage'
import { isEmpty } from '@/common/validate'

export default {
  name: 'CreditsExchange',
  components: {
    [Card.name]: Card,
    [Field.name]: Field,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [SubmitBar.name]: SubmitBar,
    [Switch.name]: Switch
  },
  mixins: [PageMixin],
  data() {
    return {
      address: {},
      cartList: []
    }
  },
  computed: {
    totalPrice() {
      const price = this.cartList.reduce((total, item) => total + item.sale_price * item.count, 0)
      return price > 0 ? parseFloat(price.toFixed(2)) : '0'
    }
  },
  created() {
    this.setAddress()
    this.cartList = storage.session.get('creditsExchangeList', [])
  },
  methods: {
    onAddressChoose() {
      this.$router.push({ path: '/address-list', query: { isChoose: true }})
    },
    setAddress() {
      const address = storage.session.get('choseAddress', null)
      if (address) {
        this.address = address
      } else {
        this.$api.address_default()
          .then(res => {
            if (isEmpty(res.data)) {
              // 无默认地址
              this.address = address
            } else {
              this.address = { ...res.data, id: res.data.aid, tel: res.data.phone, address: `${res.data.province}${res.data.city}${res.data.dist}${res.data.addrs}` }
            }
          })
      }
    },
    onSubmit() {
      const params = {
        product_id: this.$route.query.product_id,
        aid: this.address.id
      }
      this.$toast.loading({
        mask: true,
        message: '兑换中...',
        duration: 0
      })
      this.$api.credits_exchange(params)
        .then(res => {
          this.$toast.success('兑换成功')
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('兑换失败')
          console.error(err)
        })
        .finally(() => {
          storage.session.remove('creditsExchangeList')
          storage.session.remove('choseAddress')
        })
    }
  }
}
</script>

<style lang="less" scoped>

    .address-box{
    position: relative;
    box-sizing: border-box;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:8px 15px;
    background: #fff;
    &-hd{
      flex: 1;
      // padding:0 10px;
      padding-left: 20px;
    }
    &-bd{
      padding-left: 5px;

    }
    &-inner{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-icon{
        position: absolute;
        top:2px;
        left: -20px;
      }
      &-title{
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 5px;
      }
      &-bottom{
        font-size: 12px;
        color: #666;
      }
    }
    &:before{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background-size: 80px;
      content: '';
    }
  }
  .address-card{
    position: relative;
    padding: 10px 15px;
    align-items: center;
    &:before{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #00de93 0, #00de93 45%, transparent 0, transparent 50%);
      background-size: 80px;
      content: '';
    }
  }
  .address-card .van-cell__left-icon{
    color: #00de93;
    font-size: 40px;
  }
  .address-card .van-cell__title{
    line-height: 40px;
  }
    .order-info{
    box-sizing: border-box;
    width:100%;
    // padding:0 15px;
    // background: #fff;
  }
  .submit-bar-left{
    flex: 1;
    padding-right: 12px;
    color: #323233;
    font-weight: 500;
    text-align: right;
  }
  .submit-bar-left span{
    display: inline-block;
  }
  .submit-bar-price{
    color: #f44;
    font-size: 18px;
  }
</style>

